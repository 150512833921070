
.SearchbarNav {
  
}
.SearchbarNav .searchBarMadin{
    background-image: linear-gradient(to left, rgba(56, 67, 81, 1), rgba(56, 67, 81, 1),rgba(56, 67, 81, 1), rgba(255,255,255,0), rgba(255,255,255,0));
    border-radius: 30px;
}
.SearchbarNav .searchBarDiv{
    background-color: white;
    padding: 6px;
    display: flex;
    border-radius: 100px;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
}
.SearchbarNav .linksDiv{
    height: 60px;
    position: relative;
}
.SearchbarNav .linksDiv a{
    padding-right: 20px;
    text-decoration: none;
    color: white;
}
.SearchbarNav .linksDiv label{
    margin-right: 8px;
    margin-bottom: 0;
}
.SearchbarNav .searchDropdownEl{
    padding: 10px;
    border: none;
    border-radius: 100px;
    outline: none;
    width: 80px;
    margin-right: 20px;
}
.SearchbarNav .searchBar{
    padding: 10px;
    border: none;
    width: 100%;
    background-color: #f2f2f2;
    border-radius: 100px;
    outline: none;
}


/* media query ================== */
@media(max-width: 590px){
    .SearchbarNav .searchDropdownEl{
        margin-left: 20px;
    }
}
