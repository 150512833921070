.Login{
  
}
.Login section{
    max-width: 900px;
    margin: 0 auto;
}
.Login .padding-tb-1{
    padding-top: 10px;
    padding-bottom: 10px;
}
.Login .text-align-right{
    text-align: right;
}
.Login .text-align-center{
    text-align: center;
}
.Login .link{
    font-weight: normal;
}
.Login .title{
    font-size: 40px;
    font-weight: bold;
    letter-spacing: 1px;
    margin-bottom: 0;
}
.Login .version{
    color: #e67474;
    font-weight: bold;
}
.Login .inputField{
    display: block;
    width: 100%;
    padding: 16px 0;
    color: #495057;
    border: none;
    border-bottom: 1px solid #ced4da;
    border-radius: 0;
    font-size: 1rem;
    background-color: transparent;
}
.Login .loginButton{
    font-weight: bold;
    cursor: pointer;
    border-radius: 100px;
    width: 100%;
    color: white;
    background-color: #e67474;
    border: none;
    padding: 10px 24px;
    text-decoration: none;
    font-size: 16px;
}
.Login #bgImage{
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    height: 100vh;
}


/*media query----------------------------------------*/
/*tablets*/
@media (max-width: 768px){
   
}
/*before gets to smartphones*/
@media (max-width: 590px){

    section{
        padding: 0 10px;
    }
    
}
  /*end media query------------------------------------*/
  
/* end style============================================ */
