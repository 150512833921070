.Alert div{
    padding: 10px 20px;
    margin: 16px 0;
    border: 1px solid gray;
    border-radius: 5px;
    color: #5b5b5b;
    background-color: #f2f2f2;
  }
.Alert .warning{
    background-color: #fff3cd;
    border-color: #ffeaaa;
  }
.Alert .danger{
    background-color: #ffd8d8;
    border-color: #ff9191;
  }
  