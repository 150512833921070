
/* desktop nav=========================================== */
.Navbar .desktopNavContainer{
    background-color: transparent;
    height: 120px;
    max-width: 900px;
    /* margin: 0 auto; */
}
.Navbar .desktopNavContainer a{
    padding: 14px 16px;
    text-decoration: none;
    font-size: 17px !important;
    color: #808f95;
}

/* mobile nav============================================ */
.Navbar .mobileNavContainer{
    background-color: transparent;
    position: relative;
    display: none;
}
.Navbar .mobileNavContainer .myNavLinks{
    display: none;
    position: absolute;
    width: 100%;
    top: 100%;
    background-color: rgba(60, 57, 57, 1);
}
.Navbar .mobileNavContainer a{
    color: white;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 17px;
    display: block;
    /* background-color: #fcfbfa; */
}
.Navbar .mobileNavContainer .navHamburgerIcon{
    background-color: transparent;
    color : black;
    display: block;
}
.Navbar .mobileNavContainer a:hover{
    background-color: #4c4c4c;
}
.Navbar .mobileNavContainer .diagnosticsLink{
    display: none;
}

/* both============================================ */
.Navbar{
    position: absolute;
    width: 100%;
    top: 0;
    z-index: 99;
    font-family: sans-serif;
}
/* media query for nav position ================== */
@media(max-width: 768px){
    .Navbar{
        position: fixed;
    }
}


/*media query----------------------------------------*/
/*tablets*/
@media (max-width: 768px){
 
}
/*before gets to smartphones*/
@media (max-width: 590px){
  
}
  /*end media query------------------------------------*/
  
/* end style============================================ */
