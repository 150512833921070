.Library{
  
}
.Library section{
    max-width: 900px;
    margin: 0 auto;
}
.Library .loading{
    width: 100%;
    height: 100vh;
    background-color: rgba(0,0,0,0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
}


/* media query=================================================== */
@media(max-width: 768px){
    .disp-flex{
        flex-wrap: wrap;
    }
    .flex-100{
    flex: 1 1 100%;
    }
    .flex-95{
    flex: 1 1 100%;
    }
    .flex-85{
    flex: 1 1 100%;
    }
    .flex-80 {
    flex: 1 1 100%;
    }
    .flex-75{
    flex: 1 1 100%;
    }
    .flex-40{
    flex: 1 1 100%;
    }
    .flex-50{
    flex: 1 1 100%;
    }
    .flex-66{
    flex: 1 1 100%;
    }
    .flex-60{
    flex: 1 1 100%;
    }
    .flex-66{
    flex: 1 1 100%;
    }
    .flex-33{
    flex: 1 1 100%;
    }
    .flex-25{
    flex: 1 1 100%;
    }
    .flex-20{
    flex: 1 1 100%;
    }
    .flex-15{
    flex: 1 1 100%;
    }
    .flex-10{
    flex: 1 1 100%;
    }
}

/*before reaches smartphones res*/
@media (max-width: 590px){
    header h1{
        font-size: 46px;
    }
    header p{
        font-size: 18px;
    }
    section h1{
        margin-left: 0px;
        margin-right: 0px;
    }
    section{
        padding: 0 10px;
    }
}
